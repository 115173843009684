<template>
    <div>
        <section class="header-image">
            <div class="header-image-overlay"></div>
            <b-container>
                <b-row>
                    <b-col lg="7">
                        <h1>{{ $t('message.erdf.title_header') }}</h1>
                    </b-col>
                    <b-col md="5">
                        <div class="header-breadcrumb">
                            <router-link class="link-alt" :to="{ name: $i18n.locale + '_home' }">{{ $t('message.home.title') }}</router-link>
                            • <span class="text-muted">{{ $t('message.erdf.title') }}</span>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <section class="section bg-gray-alt">
            <div class="container text-center">
                <img class="img-fluid" src="../assets/images/feder_pope_cdti_nuevo_h.png" alt="IBRIDGE">
                <div class="text-left bg-white p-4 mb-4">
                  <p>
                    Ibridge: diseño, desarrollo y validación de un Sistema Operativo avanzado de los sistemas informáticos y de comunicación necesario para la operativa diaria de los barcos de pesca de gran eslora focalizando la mejora en el contexto de la computación a través de:
                  </p>
                  <ul>
                    <li>Disponer de un entorno hardware/software unificado, simplificado y robusto.</li>
                    <li>Disponer de un sistema que permita al personal no técnico el despliegue y mantenimiento de los paquetes de software para cada uno de los barcos.</li>
                    <li>Disponer de un sistema centralizado de control de las comunicaciones de los dispositivos de a bordo.</li>
                    <li>Ofrecer un sistema eficiente y sencillo de administración y control operativo del entorno TIC a bordo de un gran barco de pesca (software y comunicaciones), introduciendo las nuevas soluciones tecnológicas en materia de computación y seguridad.</li>
                  </ul>
                  <p>
                    Gracias a la financiación de la Unión Europea, se ha podido avanzar en estos objetivos y desarrollo de las soluciones necesarias, cuyo resultado será una transformación digital dentro de los barcos.
                  </p>
                  <p>
                    El objetivo principal del proyecto es el diseño, desarrollo y validación experimental de un Sistema Operativo avanzado de los sistemas informáticos y de comunicación necesario para la operativa diaria de los barcos de pesca de gran eslora focalizando la mejora en el contexto de la computación mediante virtualización con repositorios en la nube (Cloud Computing) que permita de manera centralizada disponer de las diferentes herramientas de software necesarias para la operativa avanzada de los sistemas informáticos y de comunicación de los buques facilitando a capitanes y armadores la administración y despliegue de sus aplicaciones, la actualización de estas, la disponibilidad de sus medios informáticos, y el control de la red y de los dispositivos de comunicación que operan en el barco bajo condiciones de mayor seguridad cibernética, en conformidad con las nuevas recomendaciones en esta materia dentro del ámbito marítimo internacional.
                  </p>
                  <p>
                    Finalizada la primera etapa del proyecto (HITO) que abarca el periodo de ejecución desde el 01/09/2022 con una prórroga hasta el 29/02/2024, se han obtenidos los resultados planificados para esta etapa, habiéndose consumido el presupuesto asignado para el desarrollo del Hito, el cual asciende al importe de: 159.766,00 €
                  </p>
                </div>
                <img class="img-fluid" src="../assets/images/re-acciona-2020.jpg" alt="Re-acciona">
                <img class="img-fluid" src="../assets/images/galicia-exporta-empresas.png" alt="Galicia Exporta Empresas">
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Erdf",
        metaInfo () {
            return {
                htmlAttrs: {
                    lang: this.$i18n.locale
                },
                title: this.$t('meta.erdf.title'),
                link: [
                    { rel: 'canonical', href: 'https://www.syntelix.net' + this.$route.path.replace(/\/$/, "") }
                ],
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.$t('meta.erdf.description')
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.$t('meta.erdf.title')
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.$t('meta.erdf.description')
                    },
                    {
                        vmid: 'og:type',
                        property: 'og:type',
                        content: 'website'
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: 'https://syntelix-website.s3-eu-west-1.amazonaws.com/og.jpg'
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: 'https://www.syntelix.net' + this.$route.path
                    }
                ]
            }
        }
    }
</script>
